<template>
  <div class="landing-page">
    <div class="page">
      <div class="box">
        <div class="address shadow">
          <div class="title">您需要的注册地址是：</div>
          <div class="tip">
            <span>* </span>务必填写正确！我们将为您分配注册地的专业顾问！
          </div>
          <div class="choise">
            <VDistpicker
              ref="VDistpicker"
              @province="selectProvince"
              :province="province"
              :nullProvince="nullProvince"
              @city="selectCity"
              :city="city"
              :nullCity="nullCity"
              @area="selectArea"
              :area="area"
              :hideArea="true"
            />
          </div>
          <div class="tip null"><span v-if="tip">请选择地址</span></div>
        </div>
        <div class="goto">
          <span @click="gotoLink" class="btn"></span>
        </div>
      </div>
      <div class="pic shadow">
        <div class="img">
          <img src="../../assets/images/pic.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../../utils/rem.js";
import VDistpicker from "@/components/VDistpicker";
export default {
  components: { VDistpicker },
  data() {
    return {
      id: "",
      province: "",
      city: "",
      area: "",
      nullProvince: false,
      nullCity: false,
      baseUrl: "https://h5.zyoperate.com",
      apiUrl: "https://api.zyoperate.com",
      newUrl: "",
      seletAddress: false,
      tip: false,
      // 请求数据
      askCityData: {
        province: "",
        city: "",
        area: "",
      },
      // 根据ip获取的省份城市区数据
      getAddressData: {
        province: "",
        city: "",
        area: "",
      },
    };
  },
  created() {
    // this.init();
  },
  mounted() {
    this.id = this.$route.query.id;
    this.init();
  },
  methods: {
    init() {
      var hostname = location.hostname;
      if (
        hostname.indexOf("test") > -1 ||
        hostname.indexOf("localhost") > -1 ||
        /^\d{3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(hostname)
      ) {
        this.baseUrl = "https://test-h5.zyoperate.com";
        this.apiUrl = "https://test-api.zyoperate.com";
      } else {
        this.baseUrl = "https://h5.zyoperate.com";
        this.apiUrl = "https://api.zyoperate.com";
      }
      this.getAddress();
    },
    getAddress() {
      const _this = this;
      const url = this.apiUrl + "/operation/common/ipLocation";
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.send();
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          const res = JSON.parse(xhr.responseText);
          var data = res.data;
          _this.province = data.province || "";
          _this.city = data.city || "";
          _this.area = data.district || "";
          _this.getAddressData.province = data.province || "";
          _this.getAddressData.city = data.city || "";
          _this.getAddressData.area = data.district || "";
        }
      };
    },
    showTip() {
      if (this.askCityData.province && this.askCityData.city) {
        this.tip = false;
      } else {
        this.tip = true;
      }
    },
    // 选择省份
    selectProvince(e) {
      this.askCityData.province = e.value != "省份" ? e.value : "";
      this.nullProvince = this.askCityData.province ? false : true;
      if (this.askCityData.province != this.getAddressData.province) {
        this.seletAddress = true;
      }
      this.showTip();
    },
    selectCity(e) {
      this.askCityData.city = e.value != "城市" ? e.value : "";
      this.nullCity = this.askCityData.city ? false : true;
      if (this.askCityData.city != this.getAddressData.city) {
        this.seletAddress = true;
      }
      this.showTip();
    },
    selectArea(e) {
      this.askCityData.area = e.value != "区" ? e.value : "";
      if (this.askCityData.area != this.getAddressData.area) {
        this.seletAddress = true;
      }
    },
    gotoLink() {
      if (!this.askCityData.province) {
        this.nullProvince = true;
      }
      if (!this.askCityData.city) {
        this.nullCity = true;
        this.tip = true;
        return false;
      }
      const _this = this;
      const content =
        "请确认注册地区为：" +
        this.askCityData.province +
        "-" +
        this.askCityData.city;
      if (!this.seletAddress) {
        this.$confirm({
          title: "",
          content: content,
          centered: true,
          okText: "确认",
          icon: () => {},
          cancelText: "取消",
          onOk: () => {
            _this.openLink();
          },
          onCancel() {},
        });
      } else {
        this.openLink();
      }
    },
    openLink() {
      // https://h5.zyoperate.com/receiveFans?id=19
      const link = encodeURIComponent(window.location.href);
      const province = encodeURIComponent(this.askCityData.province);
      const city = encodeURIComponent(this.askCityData.city);
      const area = encodeURIComponent(this.askCityData.area);
      // "/h5/receiveFansH5.html?id=" +
      this.newUrl =
        this.baseUrl +
        "/receiveFans?id=" +
        this.id +
        "&link=" +
        link +
        "&province=" +
        province +
        "&city=" +
        city +
        "&area=" +
        area;
      // console.log(this.newUrl);
      setTimeout(() => {
        window.location.href = this.newUrl;
      }, 100);
    },
  },
};
</script>

<style lang="less">
.landing-page {
  max-width: 7.5rem;
  min-height: 100vh;
  // padding-top: 22%;
  margin: auto;
  // height: 3246px;
  // height: 32.46rem;
  background: linear-gradient(
    225deg,
    #1243ba 0%,
    #184ec6 15%,
    #174bcb 30%,
    #2c6adc 47%,
    #1241b4 100%
  );
  background-image: url("https://static.zyoperate.com/2023/0724/1653/1690188835671064be3c23a3d15.jpg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 0 -0.1rem;
  .box {
    padding-top: 5rem;
  }
  .address {
    width: 6.7rem;
    border-radius: 0.26rem;
    margin: 0 auto;
    background: #fff;
    margin-bottom: 0.3rem;
  }
  .address .title {
    font-size: 0.4rem;
    height: 0.5rem;
    line-height: 0.5rem;
    font-weight: bold;
    margin-bottom: 0.3rem;
    padding-top: 0.4rem;
    text-align: center;
    color: #333;
    box-sizing: initial;
    span {
      padding-left: 0.1rem;
      color: #ff4a4a;
    }
  }
  .link {
    a {
      display: block;
      width: 100%;
    }

    img {
      display: block;
      max-width: 100%;
      margin: auto;
    }
  }
  .choise {
    height: 0.8rem;
    border-radius: 0;
    margin-bottom: 0.2rem;
  }
  .page {
    padding-bottom: 0.3rem;
  }
  .goto {
    padding-bottom: 0.3rem;
    .btn {
      display: block;
      width: 4.36rem;
      height: 1.18rem;
      margin: 0 auto;
      background-image: url("../../assets/images/btn.png");
      background-repeat: no-repeat;
      background-size: 4.36rem auto;
    }
  }
  .tip {
    height: 0.28rem;
    line-height: 0.28rem;
    font-size: 0.24rem;
    padding-bottom: 0.3rem;
    text-align: center;
    box-sizing: initial;
    span {
      color: #ff4a4a;
    }
  }
  .null {
    text-align: left;
    padding-left: 0.6rem;
  }
  .shadow {
    box-shadow: 0 0 0.1rem #333;
  }
  .pic {
    width: 6.7rem;
    border-radius: 0.26rem;
    margin: 0 auto;
    background: #fff;
    text-align: center;
    .img {
      width: 6rem;
      margin: 0 auto;
    }
    // margin-bottom: 0.6rem;
    // background-image: url("../../assets/images/pic.jpg");
    // background-repeat: no-repeat;
    // background-position: center center;
    // background-size: auto 6.5rem;
    img {
      width: 100%;
      border-radius: 0.26rem;
    }
  }
}
body /deep/ {
  // .ant-modal-centered .ant-modal {
  //   width: 600px !important;
  // }
  // .ant-modal-confirm-body .ant-modal-confirm-content {
  //   font-size: 0.28rem;
  // }
}
@media (max-width: 767px) {
  .ant-modal-centered .ant-modal {
    max-width: calc(100vw - 30px);
  }
}
</style>
